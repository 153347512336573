import React, { useState } from 'react';
import style from './style.css';
import Close from 'cccisd-icons/cross2';

const Banner = props => {
    const [isOpen, setIsOpen] = useState(true);
    if (!isOpen) {
        return null;
    }
    return (
        <div className={style.bgColor}>
            <div className={style.closeButton}>
                <button type="button" onClick={() => setIsOpen(false)}>
                    <Close />
                </button>
            </div>
            <div className={props.className}>
                <div className={style.bannertop}>
                    <p className={style.paragraph}>
                        Welcome to the new and improved MDBehaviorHealth.com! If you had an account on the previous
                        site, you will need to create a new account log in. But don&apos;t worry, you can still access
                        certificates for previously completed courses.
                    </p>
                    <div className={style.pressButton}>
                        <a
                            href="https://archive.mdbehavioralhealth.com/"
                            className="btn btn-default btn-sm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Access previous account
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
