import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, NavbarLink, AdminDropdown, UserDropdown, ReturnToUserLink } from 'cccisd-header';
import style from './style.css';
import logo from './logo2.svg';
import Banner from '../Banner';
import { useLocation } from 'cccisd-react-router';

const Appdefs = window.cccisd.appDefs;
const Env = window.cccisd.env;
const Fortress = window.cccisd.fortress;

const wpUrl = Env.wpUrl || Appdefs.app.wpUrl;

export const publicRoutes = [
    <NavbarLink to={wpUrl} key="home" highlight="home">
        Home
    </NavbarLink>,
    <NavbarLink to="/online-training/" key="training" highlight="training">
        Training
    </NavbarLink>,
    <NavbarLink to={wpUrl + '/about/'} key="about" highlight="about">
        About
    </NavbarLink>,
    <NavbarLink to={wpUrl + '/contact/'} key="contact" highlight="contact">
        Contact
    </NavbarLink>,
    <NavbarLink to={wpUrl + '/faqs-2/'} key="faqs-2" highlight="faq-2">
        FAQs
    </NavbarLink>,
];
let isManageTab;
try {
    isManageTab =
        Fortress.auth() && Appdefs.routes.find(r => r.handle === 'manage' || r.componentHandle === 'AppdefManage');
} catch (e) {
    isManageTab = false;
}

const Header = ({ className }) => {
    const location = useLocation();

    return (
        <div>
            <div className={style.topNav}>
                <Navbar className={className} logo={null}>
                    <ul className="nav navbar-nav navbar-right">
                        {(isManageTab || Fortress.user.loggedInAsAnotherUser) && (
                            <>
                                <ReturnToUserLink />
                                {isManageTab && (
                                    <NavbarLink to="/manage" highlight="/manage">
                                        Manage
                                    </NavbarLink>
                                )}
                                {Fortress.user.acting.role.handle === 'uberadmin' && (
                                    <NavbarLink to="/quest" highlight="/quest">
                                        Data
                                    </NavbarLink>
                                )}
                                <AdminDropdown />
                            </>
                        )}
                        {Fortress.auth() ? (
                            <>
                                <UserDropdown allowSwitchRoles />
                            </>
                        ) : (
                            <>
                                <NavbarLink to="/register">
                                    <span className={style.registerLink}>
                                        <span>Register</span>
                                        <span>Login</span>
                                    </span>
                                </NavbarLink>
                            </>
                        )}
                    </ul>
                </Navbar>
            </div>
            <nav className="navbar navbar-default">
                <div className={className}>
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bottom-nav-collapse"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <NavbarBrand logo={<img src={logo} className={style.logo} alt="Maryland" />} />
                    </div>

                    <div className="collapse navbar-collapse" id="bottom-nav-collapse">
                        <ul className="nav navbar-nav navbar-right">{publicRoutes}</ul>
                    </div>
                </div>
            </nav>
            {(location.pathname === '/register' || location.pathname === '/') && (
                <>
                    <Banner className={className} />
                </>
            )}
        </div>
    );
};

Header.defaultProps = {
    className: 'container',
    allowSwitchRoles: true,
};

Header.propTypes = {
    className: PropTypes.string,
    allowSwitchRoles: PropTypes.bool,
};
export default Header;
